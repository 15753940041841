<template>
  <div class="row">
    <div class="col-lg-7 col-xl-8">
      <figure
        class="extra-rounded background-image position-relative mb-0"
        v-if="event"
        :style="{ backgroundImage: `url('${event.image}')` }"
      >
        <figcaption class="position-absolute header-caption text-white">
          {{ event.name }}
          <div class="header-city">{{ venue.name }} in {{ city.name }} on {{ eventDate }}</div>
        </figcaption>
      </figure>
      <section class="pl-xl-4 pt-4 d-none d-lg-block" v-if="venue">
        <div class="d-flex">
          <div class="font-weight-bold mr-5">
            <div class="text-secondary">Dress Code</div>
            <div class="text-white" v-if="venue">{{ venue.dress_code }}</div>
          </div>
          <div class="font-weight-bold mr-5 pl-5">
            <div class="text-secondary">Opening Times</div>
            <div class="text-white text-lowercase" v-if="venue">{{ venueOpeningHours }}</div>
          </div>
          <div class="font-weight-bold pl-5">
            <div class="text-secondary">Entry ages</div>
            <div class="text-white" v-if="venue">{{ venue.pop_age_min }}+</div>
          </div>
        </div>
        <div class="mt-5">
          <div class="text-secondary font-weight-bold">Event Information</div>
          <div class="text-white" v-if="event">{{ event.description }}</div>
        </div>
        <div class="mt-4">
          <div class="text-secondary font-weight-bold">Club Information</div>
          <div class="text-white" v-if="venue">{{ venue.description }}</div>
        </div>
      </section>
    </div>
    <div class="col-lg-5 col-xl-4 mt-4 mt-lg-0">
      <buy-tickets v-if="currentEventHasTickets" />
      <book-tables classes="mt-4" v-if="currentEventHasTables" />
    </div>
    <div class="col-12 mt-4 d-lg-none font-weight-bold">
      <div class="text-secondary">Dress Code</div>
      <div class="text-white mb-4" v-if="venue">{{ venue.dress_code }}</div>
      <div class="text-secondary">Opening Times</div>
      <div class="text-white text-lowercase mb-4" v-if="venue">{{ venueOpeningHours }}</div>
      <div class="text-secondary">Entry ages</div>
      <div class="text-white mb-4" v-if="venue">{{ venue.pop_age_min }}+</div>
      <div class="text-secondary">Event Information</div>
      <div class="text-white mb-4 font-weight-normal" v-if="event">{{ event.description }}</div>
      <div class="text-secondary">Club Information</div>
      <div class="text-white font-weight-normal" v-if="venue">{{ venue.description }}</div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { format } from 'date-fns'
import BuyTickets from '../components/BuyTickets'
import BookTables from '../components/BookTables'

export default {
  components: {
    BuyTickets,
    BookTables
  },

  computed: {
    ...mapGetters([
      'venue',
      'city',
      'event',
      'currentEventHasTickets',
      'currentEventHasTables',
      'venueOpeningHours'
    ]),
    eventDate() {
      return format(new Date(this.event.date), 'd MMMM')
    }
  },

  mounted() {
    this.setCurrentVenue(this.$route.params.venue)
    this.setCurrentEvent(this.$route.params.event)
    this.getVenue().then(() => {
      if(this.$route.query.rep != undefined){
				this.trackClick(this.$route.query.rep);
			}
      this.getOpeningHours()
      this.getCity()
      this.getAvailabilityForEvent()
    })
  },

  methods: {
    ...mapMutations(['setCurrentVenue', 'setCurrentEvent']),
    ...mapActions(['getVenue', 'getOpeningHours', 'getCity', 'getAvailabilityForEvent', 'trackClick'])
  }
}
</script>