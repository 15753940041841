<template>
	<article class="card border-0 extra-rounded mb-4" v-if="table.price > 0">
		<div
			class="position-relative card-img-top"
			:style="{ 'background-image': `url('${table.image_url}')` }"
		>
			<h5 class="card-title position-absolute m-0">{{ table.name }}</h5>
		</div>
		<div class="card-body">
			<p class="card-text text-primary">{{ table.description }}</p>
			<hr/>
			<div class="d-flex align-items-center justify-content-between mt-3 text-primary">
				<div class="tickets-text">
					Total Price
					<div class="tickets-people">{{table.size}} People</div>
				</div>
				<div class="tickets-text text-right">
					£{{ formattedTablePrice(table.price + (availability.table_booking_fee)) }}
					<div
						class="tickets-fee"
					>Including Booking Fee
					</div>
				</div>
			</div>
			<button class="btn btn-primary w-100 text-uppercase mt-3" @click="reserveTable">Reserve Table</button>
		</div>
	</article>
</template>

<script>
	import {mapMutations, mapState} from "vuex";

	export default {
		props: {
			availability: Object,
			table: Object
		},

		mounted(){
			this.$root.$on('phone-modal-closed', () => {
				if(this.phoneNumber != ''){
					this.reserveTable();
				}
			})
		},

		methods: {
			...mapMutations(['setBookingItems']),
			formattedTablePrice(tablePrice) {
				return new Intl.NumberFormat('en-GB', {
					maximumFractionDigits: 2
				}).format(tablePrice / 100)
			},
			reserveTable() {
				if (!this.userIsLoggedIn) {
					this.$modal.show('sign-in-required')
					return;
				}

				if (this.phoneNumber == '' || !(/([0-9]{11})/.test(this.phoneNumber))) {
					this.$modal.show('enter-phone-number')
					return;
				}

				this.setBookingItems({
					table: this.table
				});

				this.$router.push('/payment');
			}
		},
		computed: {
			...mapState(['userIsLoggedIn', 'phoneNumber'])
		}
	}
</script>