<template>
  <section :class="classes">
    <div class="d-flex align-items-center mb-3">
      <img src="../assets/star.svg" alt="Upcoming Events" class="img-fluid" />
      <h2 class="heading text-secondary ml-2 mb-0">Book Tables</h2>
    </div>
    <Table
      v-for="table in availability.tables"
      :key="table.id"
      :availability="availability"
      :table="table"
    />
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Table from './Table'

export default {
  props: {
    classes: String
  },

  components: {
    Table
  },

  computed: {
    ...mapState(['userIsLoggedIn']),
    ...mapGetters({ availability: 'selectedDateAvailability' })
  }
}
</script>
