<template>
	<section :class="classes">
		<div class="d-flex align-items-center">
			<img src="../assets/ticket.svg" alt="Upcoming Events" class="img-fluid"/>
			<h2 class="heading text-secondary ml-2 mb-0">Buy General Ticket</h2>
		</div>
		<form class="extra-rounded border border-dark mt-3 py-3 px-2" @submit.prevent="submit">
			<div class="extra-rounded position-relative tickets-input">
				<input
					class="extra-rounded py-3 px-4 w-100 bg-primary border-0 text-white appearance-none"
					id="ticketsInput"
					type="number"
					min="0"
					pattern="[0-9]"
					placeholder="0"
					v-model="form.tickets"
				/>
				<label
					class="position-absolute text-white pl-2 bg-primary tickets-label mb-0 mr-4"
					for="ticketsInput"
				>Tickets</label>
			</div>

			<span class="text-danger" v-if="error_msg">{{error_msg}}</span>
			<div class="d-flex align-items-center justify-content-between mt-3 text-white">
				<div class="tickets-text">Total</div>
				<div class="tickets-text text-right">
					£{{ venueTicketPriceTotal(form.tickets) }}
					<div
						class="tickets-fee"
					>Including Booking Fee
					</div>
				</div>
			</div>
			<button class="btn btn-primary text-uppercase w-100 mt-3" type="submit">Buy now</button>
		</form>
	</section>
</template>

<script>
	import {mapGetters, mapState, mapMutations} from 'vuex'

	export default {
		props: {
			classes: String
		},

		computed: {
			...mapState(['userIsLoggedIn', 'phoneNumber']),
			...mapGetters(['venue', 'venueTicketPriceTotal', 'venueTicketBookingFee', 'ticketsAvailable']),
		},

		data() {
			return {
				form: {
					tickets: null
				},
				error_msg: null
			}
		},

		mounted(){
			this.$root.$on('phone-modal-closed', () => {
				if(this.phoneNumber != '' && /([0-9]{11})/.test(this.phoneNumber)){
					this.submit();
				}
			})
		},

		methods: {
			...mapMutations(['setBookingItems']),
			submit() {
				if (!this.userIsLoggedIn) {
					this.$modal.show('sign-in-required')
					return;
				}

				if (!this.form.tickets){
					this.error_msg = 'Please write a valid number of tickets';
					return;
				}

				if (this.ticketsAvailable < this.form.tickets) {
					this.error_msg = 'Only ' + this.ticketsAvailable + ' tickets available';
					return;
				}

				if (this.phoneNumber == '' || !(/([0-9]{11})/.test(this.phoneNumber))) {
					this.$modal.show('enter-phone-number')
					return;
				}

				this.setBookingItems({
					tickets: parseInt(this.form.tickets)
				});

				this.$router.push('/payment');
			},

			beforeClose(){
				console.log('testing');
			}
		},
		watch: {
			form: {
				handler() {
					this.error_msg = null;
				},
				deep: true
			}
		}
	}
</script>
